<template>
	<div v-if="statement">
		<v-row no-gutters>
			<v-col sm="11" class="black--text font-weight-bold title">
				{{ $t("app.statement_no") }} - {{ statement.id }}
			</v-col>
			<v-col align="center" class="pb-2">
				<AppButton
					label="app.delete"
					color="error"
					icon="delete"
					:class="['mt-10']"
					@click="delete_dialog = true"
				></AppButton>
			</v-col>
		</v-row>
		<v-divider :class="['me-5', 'mb-7']"></v-divider>

		<!-- Write Statement -->
		<v-card flat class="wr_grey_1 mt-2" align="center">
			<v-card flat width="750" class="wr_grey_1">
				<v-form ref="form">
					<v-text-field
						class="title"
						v-model="statement.title"
						outlined
						tile
						flat
						hide-details
						color="greenAccent2"
						background-color="white"
						:label="$t('app.placeholder.write_statement_here')"
						@blur="updateStatement()"
					>
					</v-text-field>
				</v-form>
			</v-card>
		</v-card>

		<!-- Write positive & negative points -->
		<v-row no-gutters>
			<v-col :style="styleObj">
				<v-row no-gutters justify="center">
					<v-card
						v-for="(point, posIndex) in statement.positive_points"
						:key="posIndex"
						flat
						width="600"
						class="mt-5"
					>
						<v-text-field
							v-model="point.title"
							hide-details
							solo
							flat
							color="greenAccent2"
							background-color="white"
							:placeholder="$t(`app.placeholder.write_positive_point`)"
							@blur="updateStatement()"
							class="pa-2 rounded-lg success-border-wr"
						>
							<v-icon
								v-if="statement.positive_points.length > 1"
								small
								slot="append"
								@click="removePoint('positive', posIndex)"
								>{{ $vuetify.icons.values.close }}
							</v-icon>
						</v-text-field>
					</v-card>
				</v-row>
				<v-row no-gutters justify="center" class="my-3">
					<v-icon color="wr_blue_1" large @click="addPoint('positive')"
						>$vuetify.icons.values.plus</v-icon
					>
				</v-row>
			</v-col>
			<v-col>
				<v-row no-gutters justify="center">
					<v-card
						v-for="(point, negIndex) in statement.negative_points"
						:key="negIndex"
						flat
						width="600"
						class="mt-5"
					>
						<v-text-field
							v-model="point.title"
							hide-details
							solo
							flat
							:placeholder="$t(`app.placeholder.write_negative_point`)"
							@blur="updateStatement()"
							color="greenAccent2"
							background-color="white"
							class="pa-2 rounded-lg errorborder-wr"
						>
							<v-icon
								v-if="statement.negative_points.length > 1"
								small
								slot="append"
								@click="removePoint('negative', negIndex)"
								>{{ $vuetify.icons.values.close }}
							</v-icon>
						</v-text-field>
					</v-card>
				</v-row>
				<v-row no-gutters justify="center" class="my-3">
					<v-icon color="wr_blue_1" large @click="addPoint('negative')"
						>$vuetify.icons.values.plus</v-icon
					>
				</v-row>
			</v-col>
		</v-row>

		<!-- Confirmation box for delete operation -->
		<TheConfirmation
			v-model="delete_dialog"
			:dialog="delete_dialog"
			@cancel="delete_dialog = false"
			@confirm="deleteStatement()"
		></TheConfirmation>
	</div>
</template>

<script>
import TheConfirmation from "@/components/layout/TheConfirmation";

export default {
	name: "PositiveNegativeStatement",

	data() {
		return {
			delete_dialog: false,
		};
	},

	computed: {
		statement() {
			return this.$store.getters["statement/getStatement"](
				this.$route.params.id,
			);
		},

		styleObj() {
			return {
				[this.$vuetify.rtl ? "borderLeft" : "borderRight"]: "1px solid grey",
			};
		},
	},

	components: {
		TheConfirmation,
	},

	methods: {
		async updateStatement() {
			try {
				await this.$store.dispatch("statement/update", {
					statement_id: this.statement.id,
					data: this.statement,
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async deleteStatement() {
			try {
				this.$loader.start();

				// Delete statement
				await this.$store.dispatch("statement/delete", {
					statement_id: this.statement.id,
				});

				// Redirect to The List Page
				this.$router.push({
					name: "PositiveNegative",
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async addPoint(flag) {
			try {
				this.$loader.start();

				// Add point locally
				this.statement[flag + "_points"].push({
					title: null,
				});

				// Update at server
				await this.updateStatement();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async removePoint(flag, point_index) {
			try {
				this.$loader.start();

				// Remove point locally
				this.statement[flag + "_points"].splice(point_index, 1);

				// Update at server
				await this.updateStatement();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style scoped>
>>> .v-input input {
	text-align: center;
}
.errorborder-wr {
	border: 3px solid var(--v-error-base);
}
.success-border-wr {
	border: 3px solid var(--v-success-base);
}

>>> ::placeholder {
	color: black !important;
	opacity: 0.6 !important;
}
</style>
